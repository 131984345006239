import React, { useState } from "react";
import "../css/About_Us.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Form from "./Form.js";
import baseUrlAPI from "./BaseURL";
import icon1 from "../images/1.svg";
import icon2 from "../images/2.svg";
import icon3 from "../images/3.svg";
import icon4 from "../images/4.svg";
import icon5 from "../images/5.svg";

import community from "../images/community.png";
import curator from "../images/curator.png";
import hire from "../images/hire.png";
import top15 from "../images/15.jpg";
import top16 from "../images/16.jpg";
import top17 from "../images/17.jpg";
import identify from "../images/identify.png";
import rial from "../images/rial.png";
// import { fontSize } from '@mui/system';

const About_Us = () => {
  // api post contact us

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const [error, setError] = useState();
  const [mess, setMess] = useState(false);
  const [success, setSuccess] = useState("");
  // const [mess, setMess] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    // const Errors = [];
    if (name === "") {
      setError(" Please Fill Name");
    } else if (email === "") {
      setError("Please Fill Email");
    } else if (
      !email
        .toLocaleLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setError("Email is not valid");
    } else if (mobile === "") {
      setError("Please Fill Mobile Number");
    } else if (mobile.length !== 10) {
      setError("Please Enter 10-digit Mobile Number");
    } else if (isNaN(mobile)) {
      setError("Mobile number is not valid");
    } else if (category === "") {
      setError("Please Fill Category");
    } else if (description === "") {
      setError("Please Fill Description");
    } else {
      // submit the form
      setError("");
      setSuccess(true);
      // perform any other necessary form submission logic here
    }

    // console.log({ error });
    // if (error.length !== 0) {
    //   setError(error);
    //   return;
    // }
    // // alert(Errors)
    // setError("Message Sent Successful");
    // setSuccess(error);
    // console.log(contactUS);

    const token = localStorage.getItem("candidateToken");

    // const contactUS = {
    //   name,
    //   email,
    //   mobile,
    //   category,
    //   token,
    //   description,
    // };

    // const Errors = [];
    // if (name === "") {
    //   setError(" Please Fill Name");
    // }

    // if (email === "") {
    //   setError("Please Fill Email");
    // }

    // if (
    //   !email
    //     .toLocaleLowerCase()
    //     .match(
    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     )
    // ) {
    //   Errors.push("Email is not valid");
    // }

    // if (mobile === "") {
    //   Errors.push("Please Fill Mobile Number");
    // }

    // if (mobile.length !== 10) {
    //   Errors.push("Please Enter 10-digit Mobile Number");
    // }
    // if (isNaN(mobile)) {
    //   Errors.push("Mobile number is not valid");
    // }

    // if (category === "") {
    //   Errors.push("Please Fill Category");
    // }

    // if (description === "") {
    //   Errors.push("Please Fill Description");
    // }

    // console.log({ Errors });
    // if (Errors.length !== 0) {
    //   setError(Errors);
    //   return;
    // }
    // // alert(Errors)
    // Errors.push("Message Sent Successful");
    // setSuccess(Errors);
    // console.log(contactUS);

    const addContact_queryURL = `${baseUrlAPI}/api/addContact_query`;

    fetch(addContact_queryURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(contactUS),
    })
      .then((result) => result.json())
      .then((data) => {
        console.log("result", data);
        setError(data.data);
        // setMess(data.message)
        if (data.status === 200) {
          alert("success");
        }
      })

      .catch((e) => {
        console.log("e", e);
      });
  };
  console.log(error);

  const navigate = useNavigate();
  const contactUS = () => {
    window.scrollTo(0, 0);
    navigate("/contact-us");
  };
  const searchJOBS = () => {
    window.scrollTo(0, 0);
    navigate("/job");
  };
  return (
    <div className="col-back-soft">
      <div className="About">
        {/* <h1>About Our Company</h1> */}
        <h1>About Us</h1>
        <span className="aoc-para">
          <div>
            Premium Hiring Community is a dedicated, limited membership website
            for hiring of MBA professionals from top B-schools of India.
            <br /> Please feel free to reach out if you want to know more about
            us.
          </div>
        </span>
      </div>

      <div className="About-Our-Company ">
        <div className="container abot">
          {/* <h1 className="aoc-heading">About Our Company</h1> <br /> */}

          <div className="aoc-flex">
            <div className="aoc-flex-img padd">
              <img src={top15} alt="" />
            </div>
            <div className="aoc-flex-text padd">
              <h2>Our Mission</h2> <br />
              <p>
                PHC is a venture to enhance hiring experience for recruiters and
                candidates. Our mission is to establish PHC as the best talent
                recruitment hub in the management hiring field. We are dedicated
                to make sure that candidates can find the curated management
                jobs at PHC
                <br />
                <br /> Our platform and services are free for all our members
                but our membership is limited to premium B-schools and managers
                from sought out organizations
              </p>{" "}
              <br />
              {/* <div className="aoc-btn-flex">
                <button className="aoc-btn-contact" onClick={contactUS}>
                  Contact Us
                </button>
                <button className="aoc-btn-learn-more">Learn More</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* What We Can Do? */}

      <div className="wwcd-pad">
        <div className="What-We-Can-Do container">
          <div className="wwcd-text">
            <h2>How do we work</h2> <br />
            <p>
              PHC focuses on niche pool of premium MBA hiring. We have combined
              the best features of a talent hunting agency and a standard job
              portal. We connect with talent acquisition team in reputed
              organizations directly and publish jobs on our website.
              <br />
              <br /> The applications are shared with TA team for shortlisting
              candidates and conducting interviews. Our standard CV format,
              direct channel with organizations & identified talent pool reduces
              the time taken for fulfillment of any job vacancy.
              <br />
              <br /> We consistently follow up with the TA team to ensure smooth
              and quick closure of the hiring process.
            </p>{" "}
            <br />
          </div>
          <div className="wwcd-img wwcd">
            <img
              src={top16}
              // src="https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="wwcd-pad">
        <div className="What-We-Can-Do container container">
          <div className="wwcd-img wwcd">
            <img
              style={{ marginTop: "13px" }}
              src={top17}
              // src="https://media.istockphoto.com/photos/photo-of-candidates-waiting-for-a-job-interview-picture-id1137038796?b=1&k=20&m=1137038796&s=170667a&w=0&h=Fb47hwsSnsd9z71cKAeucZxMX18Za7tBb2Qla1_FHjM="
              alt=""
            />
          </div>
          <div className="wwcd-text" style={{ textAlign: "right" }}>
            <h2>What makes us different?</h2> <br />
            <p>
              Our commitment to clients sets us apart from the rest. We work
              hard to ensure that each client is completely satisfied with our
              services.
              <br />
              <br /> We also go above and beyond to make sure that our clients
              are getting the best possible candidates for their open positions.
              We only work with the best and most qualified management
              candidates.
              <br />
              <br /> We take time to get to know each candidate and their
              qualifications. This allows us to match each client with the
              perfect candidate for their needs.
            </p>{" "}
            <br />
          </div>
        </div>
      </div>
      <div className="About-Our-Company">
        <div className="container abot">
          <h1 className="aoc-heading"></h1> <br />
          <div className="aoc-flex">
            <div className="aoc-flex-text">
              <h2>Features of PHC : </h2> <br />
              <div className="featuresContainer">
                <img src={icon1} alt="" className="img_f" />
                <div className="features-text">
                  <div className="full">Dedicated Talent Pool </div>
                  <p>
                    We maintain our own talent pool at our website and don’t
                    borrow data from any existing job platforms. Our candidates
                    are the best in business and our data is updated on a real
                    time basis to make sure that only interested candidates’
                    profiles are available to the recruiters.
                  </p>{" "}
                </div>
              </div>
              <br />
              <div className="featuresContainer">
                <img src={icon2} alt="" className="img_f" />
                <div className="features-text">
                  <div className="full">
                    Curated jobs for best in the market{" "}
                  </div>
                  <p>
                    PHC caters to the job opportunities with minimum CTC of Rs
                    15 lacs per annum. We make sure that only the most premium &
                    challenging roles in the job market are posted on our
                    portal.
                  </p>{" "}
                  <br />
                </div>
              </div>
              <div className="featuresContainer">
                <img src={icon3} alt="" className="img_f" />
                <div className="features-text">
                  <div className="full">Enhanced Hiring Efficiency </div>
                  <p>
                    Our best in class hiring practices makes us the most
                    advanced and efficient talent sourcing agency. We have a
                    direct communication channel with the TA teams of
                    organizations which helps our candidates to escape the crowd
                    of regular candidates.
                  </p>{" "}
                  <br />
                </div>
              </div>
              {/* <span className="full">
                <img src={hire} alt="" className="img_f" /> &nbsp;&nbsp;Enhanced
                Hiring Efficiency
              </span>
              <p>
                Our best in class hiring practices makes us the most advanced
                and efficient talent sourcing agency. We have a direct channel
                with the TA teams of organizations which helps our candidates to
                escape the crowd of regular candidates.
              </p>{" "}
              <br /> */}
              <div className="featuresContainer">
                <img src={icon4} alt="" className="img_f" />
                <div className="features-text">
                  <div className="full">CV Creator Tool</div>
                  <p>
                    While creating their profile on the profile, a CV will be
                    automatically generated as per the data given by the
                    candidates. This removes the strain of uploading CVs,
                    selecting the right template etc. The candidates can make
                    the changes in the CV created later as they want.
                  </p>{" "}
                  <br />
                </div>
              </div>
              {/* <span className="full">
                <img src={identify} alt="" className="img_f" /> &nbsp;&nbsp;CV
                Creator Tool
              </span>
              <p>
                While creating their profile on the profile, a CV will be
                automatically generated as per the data given by the candidates.
                This removes the strain of uploading CVs, selecting the right
                template etc. The candidate can make the changes in the CV
                created later as they want.
              </p>{" "}
              <br /> */}
              {/* <span className="full">
                {" "}
                <img src={rial} alt="" className="img_f" /> &nbsp;&nbsp;Real
                time update of your application
              </span> */}
              <div className="featuresContainer">
                <img src={icon5} alt="" className="img_f" />
                <div className="features-text">
                  <div className="full">
                    Real time update of your application{" "}
                  </div>
                  <p>
                    We provide regular updates of any open vacancy and target
                    short turnaround time for fulfillment of job vacancy.
                    {/* Feel free to get in touch with us
                through below mail ID: (We are a small team as of now but we
                will try our best to answer to your queries) */}
                  </p>{" "}
                </div>
              </div>
              {/* <div className="aoc-btn-flex">
                <button className="aoc-btn-contact" onClick={contactUS}>
                  Contact Us
                </button>
                <button className="aoc-btn-learn-more">Learn More</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Our Happy Customer */}

      {/* <div className="Our-Happy-Customer">

                <div className="container">
                <h1 className='ohc-heading'>Our Happy Customer</h1> <br />
                <p className='ohc-para'>
                    When it comes to choosing the right web hosting provider, we know how easy it <br />
                    is to get overwhelmed with the number.
                </p>

                <div className="ohc-flex ">
                    <figure className='customer ohc-p-cus'>
                        <blockquote>
                        “PHC is a game changer. They are the only true player for premium management jobs.  
                        PHC’s CV builder tool helps you to create the best resume which can be used for jobs 
                        outside the site as well. as well - PERFECT. Thank you.”
                            
                        </blockquote>
                        <div className="arrow">
                            <PlayArrowIcon className='arrow-rot' sx={{ fontSize: 40 }}/>
                        </div>

                        <div className="author-flex">
                            <img className='cus-img' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU" alt="sq-sample3" />
                            <div className="author">
                                <h5>Mark Adari <br /> <span> Recruiter</span></h5>
                            </div>
                        </div>
                        
                    </figure>

                    <figure className='customer ohc-p-cus'>
                        <blockquote>
                        “I started using PHC portal when I started my job search and soon found multiple opportunities 
                        fitting my requirement. PHC is the go to option for professionals from premier MBA institutes”

                        
                        </blockquote>
                        <div className="arrow">
                            <PlayArrowIcon className='arrow-rot' sx={{ fontSize: 40 }}/>
                        </div>
                        <div className="author-flex">
                            <img className='cus-img' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU" alt="sq-sample3" />
                            <div className="author">
                                <h5>Mark Adari <br /> <span> Recruiter</span></h5>
                            </div>
                        </div>
                    </figure>

                    <figure className='customer'>
                        <blockquote>
                        “PHC has a very simple and elegant hiring process. They have an amazing CV builder tool and
                         they provide regular updates on the hiring status. The best part is, all their services are 
                         free and still better than many prominent players in the job market!”  

                            
                        </blockquote>
                        <div className="arrow">
                            <PlayArrowIcon className='arrow-rot' sx={{ fontSize: 40 }}/>
                        </div>
                        <div className="author-flex">
                            <img className='cus-img' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRugMCl7Y_-i2mW0Y6Y1jjrrl5sp-q9c1B6Kw&usqp=CAU" alt="sq-sample3" />
                            <div className="author">
                                <h5>Mark Adari <br /> <span> Recruiter</span></h5>
                            </div>
                        </div>
                    </figure>
                </div>

                <div className="btn-ohc">
                <button className='ohc-load-more'>Load More</button>
                </div>
                </div>

            </div> */}

      {/* Get in Touch */}

      <Form />
    </div>
  );
};

export default About_Us;
